<template>
  <table class="table table-compact table-striped table-responsive w-100">
    <thead>
      <tr>
        <th>{{$t('packages.barcode')}}</th>
        <th>{{$t('packages.size')}}</th>
        <th>{{$t('packages.status')}}</th>
        <th width="50%">{{$t('common.name')}}</th>
        <th width="50%">{{$t('locations.label')}}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in this.packages" :key="item.id">
        <td>
          <template v-if="shipmentsAccess">
            <router-link :to="{name: `packages-show`, params: {id: item.id, parent_id: item.shipment_id}}" class="text-info no-icon">
              {{item.barcode}}
            </router-link>
          </template>
          <span v-else>{{item.barcode}}</span>
        </td>
        <td>{{item.size}}</td>
        <td nowrap><Status :item="item" /> </td>
        <td>{{item.consignee}}</td>
        <td>{{item.location_name}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Status from '@/components/Status.vue'
import { accessCheck } from '@/router'
export default {
  components: { Status },
  data() {
    return {
      shipmentsAccess: accessCheck('packages-show')
    }
  },
  props: {
    packages: {
      type: Array,
      required: true
    }
  }
}
</script>