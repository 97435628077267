<template>
  <MainLayout>
    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
      style="min-height: 90vh"
    >
      <div v-if="item">
        <h1>
          <i :class="['bx', resource.icon]"></i>
          {{ item.$meta.title }}
        </h1>

        <action-bar v-bind="{item,resource}" @action="action" />

        <div class="row row-cols-1 row-cols-md-2 mt-4">
          <div class="col mb-4">
            <b-card>
              <template #header> 
                <i class='bx bxs-receipt' />
                {{$t('manifests.label')}}
              </template>
              <dl>
                <div class="row row-cols-1 row-cols-xl-2">
                  <div class="col">
                    <value label="common.name" icon="bx-purchase-tag" :value="item.name"/>
                  </div>
                  <div class="col">
                    <value label="manifests.mode" :icon="modeIcon" :value="true">
                      {{$t(`manifests.modes.${item.mode}`)}}
                    </value>
                  </div>
                  <div class="col">
                    <value label="shipments.title" icon="bxs-truck" :value="item.shipment_count"/>
                  </div>
                  <div class="col">
                    <value label="packages.title" icon="bx-package" :value="item.package_count"/>
                  </div>
                </div>
              </dl>

            </b-card>

            <b-card no-body>
              <template #header> 
                <i class='bx bxs-analyse' />
                {{$t('common.status')}}
              </template>
              <table class="table">
                <tbody>
                  <tr v-for="(count,status) in statusDistribution" :key="status">
                    <td><Status :item="new Package()" :value="status" /></td>
                    <td>{{count}}</td>
                  </tr>
                </tbody>
              </table>
            </b-card>

          </div>
          <div class="col mb-4">
            <activity-card :item="item" />
          </div>
        </div>

        <b-card no-body>
          <template #header> 
            <div class="float-right" v-if="item.shipment_count">
              <b-button :to="{name:'shipments',params:{filters:{'manifest':[item.id]}}}" variant="info">
                  <i class='bx bxs-truck' /> {{$t('manifests.shipments',{count: item.shipment_count})}}
              </b-button>
            </div>
            <i class='bx bxs-package' />
            {{$t('packages.title')}}
          </template>
          <PackagesList :packages="item.$packages" />
        </b-card>

      </div>
      <debug v-bind="{item}" />
    </b-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import ItemShow from '@/components/ItemShow'
import Value from "@/components/Value";
import ActionBar from '@/components/ActionBar.vue'
import ActivityCard from '@/components/Cards/Activity'
import PackagesList from './partials/PackageList.vue'
import Status from '@/components/Status.vue';
import Package from '@/resources/Package';

export default {
  extends: ItemShow,
  components: { 
    MainLayout,
    Value,
    ActionBar,
    ActivityCard,
    PackagesList,
    Status
  },
  data() {
    return {
      Package
    }
  },
  computed: {
    modeIcon() {
      return this.resource.modes.find(m => m.code === this.item.mode).icon
    },
    statusDistribution() {
      return this.item.$packages.reduce((c,item) => {
        if(!(item.status in c)) c[item.status] = 0
        c[item.status]++
        return c
      },{})
    },

  }
};
</script>